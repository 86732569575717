/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox'; 
import Typography from '@material-ui/core/Typography';
import Button from "../../components/Button";
import { withStyles } from '@material-ui/core/styles';

// const CustomSwitch = withStyles({
//     switchBase: {
//       color: '#326dff',
//       '&$checked': {
//         color: '#326dff',
//       },
//       '&$checked + $track': {
//         backgroundColor: '#326dff',
//       },
//     },
//     checked: {},
//     track: {},
// })(Switch);

const CustomCheckbox = withStyles({
    root: {
      color: '#326dff',
      '&$checked': {
        color: '#326dff',
      },
    },
    checked: {},
})(Checkbox);

const PickupControl = (props) => {
    const {terminals, setTerminals, saveData} = props;
    const classes = useStyles();

    const handleTerminalClick = (index) => {
		const updatedTerminals = [...terminals];
		updatedTerminals[index].isActive = !updatedTerminals[index].isActive;
		setTerminals(updatedTerminals);
	};
    
    return(
        <div className={classes.wrapper} >
        <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
            <Typography variant="h6" className={classes.title}>
                 Контроль точек самовывоза
            </Typography>
            {terminals?.map((terminal, index) => (
				<div key={terminal.id} className={classes.terminalContainer}>
					{/* <CustomSwitch
						checked={terminal.isActive}
						onChange={() => handleTerminalClick(index)}
					/> */}
                    <CustomCheckbox
                        checked={terminal.isActive}
                        onChange={() => handleTerminalClick(index)}
                    />
					<div>Включить самовывоз на <b>{terminal.name}</b></div>
				</div>
			))}
            <div className={classes.buttonContainer}>
            <Button 
                className={classes.button} 
                load={false}
                text={'Сохранить'}
                handleClick={saveData}
            />
            </div>
            </Grid>
        </Grid>
        </div>
    )
} 
const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: '24px 16px 24px 16px',
        background: '#FFF',
        border:' 0.5px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: '5px 5px 0px 0px',
    },
    terminalContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        marginBottom: 10,
        fontSize: 20
    },
    buttonContainer: {
        margin: 16
    },
    button: {
        textTransform: 'none',
    },
}))

export default PickupControl;