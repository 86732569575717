import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { withFirebase } from "../../../../firebase";
import {
	updateCategories,
	updateProduct,
	editProduct,
	updateLanguages,
} from "../../../../reducers/shops";
import { withRouter } from "react-router-dom";

import EditProduct from "./editProduct";
import Loading from "../../../../components/Loading";

const EditProductContainer = (props) => {
	const dispatch = useDispatch();
	const login = useSelector((state) => state.auth.login);
	const defaultLanguage = useSelector((state) => state.shops.defaultLanguage);
	// const languages = useSelector(state => state.shops.languages);
	const cityId = props.match.params.id;

	const [preload, setPreload] = useState(false);
	const [products, setProducts] = useState(null);
	const [cities, setCities] = useState(null);
	const [languages, setLanguages] = useState(null);
	const [product, setProduct] = useState(null);
	const [categories, setCategories] = useState([]);
	const [citiesSpot, setCitiesSpot] = useState([]);
	const [sortLanguages, setSortLanguages] = useState(null);
	const [deliveryTerminals, setDeliveryTerminals] = useState(null);
	const [deliveryTerminalsCheckBox, setDeliveryTerminalsCheckBox] =
		useState(null);
	const [isOpenCities, setIsOpenCities] = useState(true);

	const brand = JSON.parse(localStorage.getItem("currentBrand"));
	const isDebug = JSON.parse(localStorage.getItem("isDebug"));

	useEffect(() => {
		getLanguages();
		getCategories();
		getIsOneCities();
	}, []);

	useEffect(() => {
		if (languages) {
			sortedLanguages();
		}
	}, [languages]);

	useEffect(() => {
		if (!product && products) {
			getProduct();
		}

		if (!products) {
			getProducts();
		}
	}, [products]);

	useEffect(() => {
		if (!cities) {
			getCities();
		}
	}, [cities]);

	const getIsOneCities = () => {
		// brand.deliveryTerminals.length > 1
		// 	? setIsOpenCities(true)
		// 	: setIsOpenCities(false);
	};

	const getCategories = () => {
		props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/categories`
			)
			.once("value", (snapshot) => {
				let categories = snapshot.val();
				setCategories(categories);
			});
	};

	const getProducts = () => {
		props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/products`
			)
			.once("value", (snapshot) => {
				const products = snapshot.val();
				if (products) setProducts(products);
			});
	};

	const getProduct = () => {
		const product = products.find((item) => item.productId === cityId);
		setProduct(product);

		// for (let item in products) {
		// 	if (item.productId === cityId) {
		// 		setProduct(products[cityId]);
		// 	}
		// }
	};

	const getLanguages = () => {
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/adminPermission/languages`)
			.once("value", (snapshot) => {
				let languages = snapshot.val();

				setLanguages(languages);
			});
	};

	const sortedLanguages = () => {
		languages.sort((a, b) => {
			if (a.key !== defaultLanguage) {
				return 1;
			} else {
				return -1;
			}
		});

		setSortLanguages(languages);
	};

	const getCities = () => {
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/settings/City`)
			.once("value", (snapshot) => {
				let data = snapshot.val();

				if (data) setCities(data);
			});
	};

	const handleDeliveryTerminalsCheckBox = (data) =>
		setDeliveryTerminalsCheckBox(data);
	const handleChangeCitiesSpot = (data) => setCitiesSpot(data);
	const handleIsOpenCities = () => setIsOpenCities(!isOpenCities);

	const createProduct = (data) => {
		let languagesName = {};
		let languagesDescription = {};

		for (let index in languages) {
			let key = languages[index].key;
			let name = "name_" + key;
			let description = "description_" + key;

			if (data[name]) {
				languagesName[key] = data[name];
			}

			if (data[description]) {
				languagesDescription[key] = data[description];
			}
		}

		let spots = [];

		const category = categories[+data.categories];

		for (let key in data.terminals) {
			const terminalId = key;
			citiesSpot.map(({ id, visible }) => {
				if (String(terminalId) === String(id)) {
					let spot = {
						price:
							data.firstPrice && !isOpenCities
								? String(Number(data.firstPrice * 100))
								: String(Math.round(+data.terminals[id] * 100)),
						oldPrice: data.oldPrice
							? String(Math.round(data.oldPrice * 100))
							: "",
						spotId: id,
						visible: data.firstPrice && !isOpenCities ? "1" : visible,
					};
					spots.push(spot);
				}
			});
		}

		setPreload(true);
		const updatedProduct = {
			categoryId: category.categoryId,
			productId: product.productId,
			name: languagesName,
			description: languagesDescription,
			spots,
		};

		return updatedProduct;
	};

	const onSubmit = (data) => {
		console.log("data --- ==>>> ", data);
		let updatedProduct = createProduct(data);
		console.log("product", updatedProduct);

		if (data.photo) {
			let file = new FormData();
			file.append("photo", data.photo[0]);
			file.append("login", login);
			axios
				.post(`https://e-admin.com.ua/photo/index.php`, file)
				.then((res) => {
					const namePhoto = res.data.image_name.split(" ")[0];
					const isSuccess = res.data.check_upload_image;
					// console.log('res ---- >>> ', res);
					// console.log('product ---- >>> ', product);

					updatedProduct.photo = `https://e-admin.com.ua/photo/photo/uploads/${login}/${namePhoto}`;

					if (isSuccess) {
						props.firebase.bd
							.ref(
								`${isDebug ? "debug" : ""}/EAppCRM/${
									brand.id
								}/nomenclature/products`
							)
							.once("value")
							.then((snapshot) => {
								const products = snapshot.val() || [];

								const updatedProducts = products.map((item) =>
									item.productId === updatedProduct.productId
										? { ...updatedProduct }
										: { ...item }
								);

								console.log("updatedProducts ------>", updatedProducts);

								props.firebase.bd
									.ref(
										`${isDebug ? "debug" : ""}/EAppCRM/${
											brand.id
										}/nomenclature/products`
									)
									.set(updatedProducts)
									.then(() => {
										// updateProductsDispatch(dispatch, products);
										setPreload(false);
										props.history.push(
											`/page/menu/products/edit/category${updatedProduct.categoryId}`
										);
									})
									.catch((error) => {
										console.error("Error adding product:", error);
									});
							})
							.catch((error) => {
								console.error("Error retrieving products:", error);
							});
					} else {
						alert("Ошибка, попробуйте ещё раз!");
						setPreload(false);
					}
				})
				.catch((res) => {
					alert("Ошибка, попробуйте ещё раз!");
					setPreload(false);
				});
		} else {
			// console.log('product', product)
			updatedProduct.photo = product.photo;

			props.firebase.bd
				.ref(
					`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/products`
				)
				.once("value")
				.then((snapshot) => {
					const products = snapshot.val() || [];

					const updatedProducts = products.map((item) =>
						item.productId === updatedProduct.productId
							? { ...updatedProduct }
							: { ...item }
					);

					props.firebase.bd
						.ref(
							`${isDebug ? "debug" : ""}/EAppCRM/${
								brand.id
							}/nomenclature/products`
						)
						.set(updatedProducts)
						.then(() => {
							// updateProductsDispatch(dispatch, products);
							setPreload(false);
							props.history.push(
								`/page/menu/products/${brand.id}/edit/category${updatedProduct.categoryId}`
							);
						})
						.catch((error) => {
							console.error("Error adding product:", error);
						});
				})
				.catch((error) => {
					console.error("Error retrieving products:", error);
				});
		}
	};

	// console.log('props', props);
	console.log("isOpenCities ---> ", isOpenCities);

	return product ? (
		<EditProduct
			product={product}
			onSubmit={onSubmit}
			handleCheckBox={handleDeliveryTerminalsCheckBox}
			// deliveryTerminals={this.state.deliveryTerminals}
			handleChangeCitiesSpot={handleChangeCitiesSpot}
			handleIsOpenCities={handleIsOpenCities}
			isOpenCities={isOpenCities}
			cityId={cityId}
			cities={cities}
			languages={languages}
			defaultLanguage={defaultLanguage}
			categories={categories}
			products={products}
		/>
	) : (
		<Loading />
	);
};

export default withFirebase(withRouter(EditProductContainer));
