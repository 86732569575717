import React, { useState, useEffect } from 'react';
import 'emoji-mart/css/emoji-mart.css'

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {connect} from 'react-redux';
import { withFirebase } from '../../firebase'
import { withRouter } from 'react-router-dom'
import { Field, reduxForm, reset, formValueSelector } from 'redux-form';

import CustomizedInputs from '../../components/UIform';
import {required} from 'redux-form-validators';
import Button from '../../components/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import RadioButtons from './radioButtons';
import ModalComponent from '../../components/ModalComponent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomizedInputsPhone from '../../components/UIform/index';

const validate = values => {
  const errors = {}
  if (values.phone) {
    const phone = values.phone.replace(/-/g, '');

    if (Number.isInteger(+phone)) {
      if (phone.length > 14) {
        errors.phone = `Максимальная длинна нормера - 14 цифр, текущий - ${phone.length} цифр`;
      }

      if (phone.length < 11) {
        errors.phone = `Минимальная длинна нормера - 11 цифр, текущий - ${phone.length} цифр`;
      }
    } else {
        errors.phone = 'Не валидный номер телефона';
    }
  }

  return errors
}

const Paragraph = ({ classes, title }) => (
  <FormLabel className={classes.formLegend} component="legend">
      <Typography className={classes.legend} variant="h6">
          {title}
      </Typography>
  </FormLabel>
) 

let Push = (props) => {
  const classes = useStyles();
  const [cityPush, setCityPush] = useState(false);
  const [brandPush, setBrandPush] = useState(false);
  const [multiUserPush, setMultiUserPush] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const { handleSubmit, pristine, submitting, reset, audience } = props;

  const handleClickOpen = () => {
   const {valid, handleSubmit} = props;

    if (valid) {
      setOpen(true);
      setIsLoading(true);
    } else {
      handleSubmit();
    }

  };

  const handleClose = () => {
    setOpen(false);
    setIsSubmit(false);
  };

  const handleClickSubmit = () => {
    setOpen(false);
    setIsSubmit(true);
    setChecked(false);
  };

  props.firebase.bd.ref(`${
    JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
  }/adminPermission`).on("value", function(snapshot) {
    let adminPermission = snapshot.val();

    if (adminPermission && adminPermission.cityPush) {
      if (cityPush !== adminPermission.cityPush) {
        setCityPush(adminPermission.cityPush);
      }
    }

    if (adminPermission && adminPermission.brandPush) {
      if (brandPush !== adminPermission.brandPush) {
        setBrandPush(adminPermission.brandPush);
      }
    }

    if (adminPermission && adminPermission.multiUserPush) {
      if (multiUserPush !== adminPermission.multiUserPush) {
        setMultiUserPush(adminPermission.multiUserPush);
      }
    }
  
  });


  useEffect(() => {
    if (isLoading && !open && !isSubmit) {
      // console.log('Вы отменили');
    } 

    if (isLoading && isSubmit && !open) {
      const {handleSubmit} = props;

      handleSubmit();
      reset();

      // console.log('Вы подтвердили');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit, open, isLoading]);

  const isMin600 = useMediaQuery('(max-width: 600px)');
  const isMax600 = useMediaQuery('(min-width: 601px)');

  const handleCheckboxChange = (e) => {
    const value = e.target.checked;

    if (checked !== value) {
        setChecked(value)
    }
  }

  // console.log('audience', !audience ? 'all': audience)

  return (
      <Grid container> 
          <Grid item xs={12}>
            {isMin600 && <Typography className={classes.titleMobile} variant="h6">Отправить Push-уведомление</Typography>}
              <Paper className={classes.paper}>
                {isMax600 && <Typography className={classes.title} variant="h6">Отправить Push-уведомление</Typography>}
                  <form onSubmit={handleSubmit} autoComplete='off' className={classes.container}>
                      <FormControl className={classes.formControl} component="fieldset">
                          <Paragraph classes={classes} title="Аудитория" />
                          <RadioButtons  cityPush={cityPush} brandPush={brandPush} multiUserPush={multiUserPush} updatePhonesList={props.updatePhonesList} {...props} />
                          <ModalComponent
                            title="Подтвердите действие!"
                            description="Вы подтверждаете отправку пуш уведомления?" 
                            open={open} 
                            handleClose={handleClose} 
                            handleClickSubmit={handleClickSubmit} />

                          <Paragraph classes={classes} title="Текст" />
                          <Field name="text"
                              className={classes.textField}
                              component={CustomizedInputs}
                              rowsMax={4}
                              type="text"
                              label=""
                              placeholder="Введите текст сообщения"
                              multiline
                              validate={[required({msg: 'Введите текст сообщения'})]} />

                            {props.productPush && (
                              <div className={classes.transitionToProduct}>
                                <FormControlLabel
                                  control={ <Checkbox 
                                              className={classes.checkbox}
                                              checked={checked} 
                                              onChange={handleCheckboxChange} 
                                              name="checkedA"
                                              color="primary" 
                                          />}
                                  label="С переходом на товар"
                                />
                                <Field name="product"
                                  style={{ display: checked ? 'block' : 'none' }}
                                  component={CustomizedInputsPhone}
                                  label=""
                                  type="text"
                                  className={classes.phoneNumber}
                                  placeholder="Введите id товара" />
                            </div>
                            )}

                            <FormLabel className={classes.buttonLabel}>
                              <Button     
                                  className={classes.button}
                                  disabled={pristine || submitting}
                                  color="primary"
                                  text={'Отправить'}
                                  handleClick={() => handleClickOpen(handleSubmit)}
                                  size={18}
                                  load={props.loadStatus}
                              />
                          </FormLabel>
                      </FormControl>
                  </form>
              </Paper>
          </Grid>
      </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
      // paddingTop: 24,
      padding: 16,
      border: '0.5px solid #DADCE0',
      boxSizing: 'border-box',
      borderRadius: 5,
      boxShadow: 'none',
  },
  title: {
      fontFamily: 'Roboto',
      fontSize: 22,
      lineHeight: 1,
      fontWeight: 'bold',
      letterSpacing: '0.003em',

    },
    titleMobile: {
      marginTop: 16,
      marginBottom: 8,
      fontFamily: 'Roboto',
      fontSize: 22,
      lineHeight: 1.3,
      textAlign: 'center',
      fontWeight: 'bold',
      letterSpacing: '0.003em',
    },
  legend: {
    fontSize: 16,
    fontWeight: 500,
    color: 'black'
  },
  formControl: {
      width: '100%',
      marginTop: 24,
      [theme.breakpoints.down("375")]: {
        marginTop: 0,
      }
  },
  formLegend: {
    marginBottom: 12,
  },
  textField: {
      fontFamily: 'Roboto',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#476282',

      width: '100%',
      height: 'auto',

      outline: 'none',
      resize: 'none',
      '& .MuiInput-inputMultiline': {
        border: '1px solid #476282',
        padding: '30px 16px',
        borderRadius: '5px',
      },
      '& .MuiInput-inputMultiline::before': {
        content: 'none',
      },
      '& .MuiInput-underline.Mui-error:after': {
        content: 'none',
      },
      '& .MuiInput-underline:before': {
        content: 'none',
      },
      '& .MuiInput-underline:after': {
        content: 'none',
      },
      '& div.Mui-error textarea': {
        border: '1px solid red',
      }
  },
      buttonLabel: {
        textAlign: 'center',
        marginTop: 16,
        width: 200,
        margin: '0 auto',
        '& .MuiButton-root.Mui-disabled': {
          color: 'white',
          backgroundColor: 'rgba(50,109,255, .35);',
        },
      },
      button: {
        width: 150,
        height: 42,
        background: '#326DFF',
        borderRadius: '5px',
        cursor: 'pointer',
        '&:hover': {
            background: '#326DFF',
        }
      },
      phoneNumber: {
        display: 'block',
        marginTop: 14,
        // marginBottom: 14,
        '& .MuiInput-formControl': {
          width: 328,
          height: 45,
          padding: '13px 14px',
          boxSizing: 'border-box',
          borderRadius: 5,
          border: '1px solid #326DFF',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          }
        },
        '& div.Mui-error': {
          border: '1px solid red',
        },
        '& .MuiInput-underline:after': {
          content: 'none',
        },
        '& .MuiInput-underline.Mui-error:after': {
          content: 'none',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          content: 'none',
        },
        '& .MuiInput-underline:before': {
          content: 'none',
        },
        '& .MuiSelect-icon': {
            right: 8,
        }
      },
      formSelect: {
        marginBottom: 16,
      },
      select: {
        marginBottom:  12,
        width: 328,
        borderRadius: 5,
        border: 'none',
        '& .MuiInput-formControl': {
          border: '1px solid #326DFF',
          borderRadius: 5,
        },
        '&:hover': {
          border: 'none',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            height: 45,
            boxSizing: 'border-box',
            padding: 12,
        },
        '& .MuiOutlinedInput-input': {
            height: 45,
            padding: 12,
            boxSizing: 'border-box'
        },
        '& .MuiInputBase-root::before': {
            content: 'none',
        },
        '& .MuiInputBase-root::after': {
            content: 'none',
        }
      },
      selectCity: {
        marginTop: 14,
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
      },
      controlLabel: {
        marginBottom: 16,
      },
      inputCheckbox: {
        display: 'flex',
      },
      info: {
        marginLeft: 8,
      },
      checkbox: {
        width: 18,
        height: 18,
        color: '#476282',
        margin: 0,
        padding: 0,
        cursor: 'pointer'
      },
      textCheckbox: {
        marginLeft: 8,
        fontFamily: 'Roboto',
        fontSize: 15,
        lineheight: '18px',
        color: '#476282',
      },
      transitionToProduct: {

      },
      checkbox: {
        '&.MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#326dff',
        },
        '&.MuiCheckbox-colorPrimary': {
            padding: 10,
        }
    },
}))

const mapStateToProps = (state) => {
    return {
      city: state.shops.shops,
    }
  }

const afterSubmit = (_, dispatch) => 
  dispatch(reset('PushForm'));

Push = reduxForm({
    form: 'PushForm',
    validate,
    onSubmitSuccess: afterSubmit,
  })(connect(mapStateToProps, null)(withFirebase(Push)));


const selector = formValueSelector('PushForm')
  Push = connect(state => {
    const {audience, phone, cityId, text}  = selector(state, 'phone', 'cityId', 'text', 'audience');
    return {
      audience: !audience ? 'all': audience, 
      phone,
      cityId,
      text
    };
  })(Push);

export default withRouter(Push);