import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	withRouter,
} from "react-router-dom";
import Products from "./index";
import * as ROUTERS from "../../../../constants/routes";

const ProductsRoute = (props) => {
	const categories = useSelector((state) => state.shops.categories);
	const brand = JSON.parse(localStorage.getItem("currentBrand"));

	// const firstCategory = Object.keys(categories)[0];
	const firstCategory = categories[0]?.categoryId;
	const { history, location } = props;

	useEffect(() => {
		if (firstCategory) {
			history.push(
				`/page/menu/products/${brand.id}/sort-mode/products/category/${firstCategory}`
			);
		}
	}, [firstCategory]);

	return (
		<>
			<div></div>
		</>
	);
};

export default withRouter(ProductsRoute);
