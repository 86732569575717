import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { withFirebase } from '../../../../firebase';
import { withRouter } from 'react-router-dom';

import { reorder } from '../other';
import { updateCategories, updateCategoriesOrder, updateCategoriesDispatch } from '../../../../reducers/shops';
import SortCategories from './sortCategories';

const SortModeProducts = (props) => {
    // const shops = useSelector(state => state.shops.shops);
    // const categories = useSelector(state => state.shops.categories);
    const language = useSelector(state => state.shops.defaultLanguage);
    const languages = useSelector(state => state.shops.languages);
    // const categoriesOrder = useSelector(state => state.shops.categoriesOrder);\

    const dispatch = useDispatch()

    const brand = JSON.parse(localStorage.getItem("currentBrand"));
    const isDebug = JSON.parse(localStorage.getItem("isDebug"));

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const [categoriesList, setCategoriesList] = useState(null);
    const [categories, setCategories] = useState(null);
    const [categoriesOrder, setCategoriesOrder] = useState(null);

    const handleClose = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };


    const getCategoriesData = () => {

        props.firebase.bd.ref(`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/categories`).once("value", (snapshot) => {
           
            const categoriesData = snapshot.val();

            const categories = categoriesData ? categoriesData.map((item) => ({
                ...item,
                id: item.categoryId,
            })) : []

           setCategories(categories)
           updateCategoriesDispatch(dispatch, categories);
        });
    }

    const getCategoriesOrderData = () => {

        const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
        const keyCity = JSON.parse(localStorage.getItem("chosenCity"));

        props.firebase.bd.ref(`${isDebug ? "debug" : ""}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`).once("value", (snapshot) => {
           
            const categoriesOrderData = snapshot.val();
            setCategoriesOrder(categoriesOrderData)
            // console.log('categoriesOrderData', categoriesOrderData);
            updateCategoriesOrder(categoriesOrderData);
            
        });
    }

    useEffect(() => {
        getCategoriesData()
        getCategoriesOrderData()
    }, [])

    
    const getCategoriesList = () => {
        // let data = [];
        // let listData = {};
        // for (let id in categories) {
            
        //     if (categoriesOrder && categoriesOrder.length > 0) {
        //         for (let key in categoriesOrder) {
        //             // console.log('categoriesOrder[key]', categoriesOrder[key]);
        //             // console.log('id -->>>', id);
        //             if (categoriesOrder[key] !== id) {
        //               listData[id] = categories[id]
        //             }
        //           }
        //     } else {
        //         listData[id] = categories[id]
        //     }
        // }

        // for (let key in listData) {
        //     // let item = { id: key, name: categories[key].name[language] };
        //     let item = { id: categories[key].id, name: categories[key].name[language] };
        //     data.push(item);
        // }

        // if (data.length > 0) setCategoriesList(data);

        // console.log('data', data);
        // console.log('listData', listData);

        const sortedCategories = [];

        // Проходим по порядку из categoriesOrder и добавляем соответствующие категории
        for (const categoryId of categoriesOrder) {
            const category = categories.find(cat => cat.categoryId === categoryId);
            if (category) {
                sortedCategories.push({ id: category.categoryId, name: category.name.uk });
            }
        }

        if (sortedCategories.length > 0) setCategoriesList(sortedCategories);

        // console.log('sortedCategories', sortedCategories);

    }


    const sortCategories = (ids) => {
        const categoriesIds = [ ...ids ];
        let data = {};

        if (categoriesOrder) {
            let categoriesOrders = [];
            const categoriesOrderIds = Object.values(categoriesOrder);

            categoriesIds.map(id => {
            categoriesOrderIds.map(orderId => {
                if (orderId === id) {
                categoriesOrders.push(id);
                }
            });
            });

            for (let key in categoriesOrders) {
            if (categories[categoriesOrder[key]]) {
                data[categoriesOrder[key]] = categories[categoriesOrder[key]]
            }
            }
        }

        console.log('data --->>>', data);

        if (data) props.updateCategories(data);
    }


    useEffect(() => {
        if (isSubmit) {
            handleSubmit(submitData);
            setOpenModal(false);
        };
    }, [isSubmit]);

    useEffect(() => {
        if (!categoriesList && categories && categoriesOrder) getCategoriesList();
        if (!categoriesList && categories && !categoriesOrder) getCategoriesList();
    }, [categoriesList, categoriesOrder]);

    // useEffect(() => {
    //     if (!categoriesList && categories && categoriesOrder) getCategoriesList();
    //     if (!categoriesList && categories && !categoriesOrder) getCategoriesList();
    // }, [categoriesList, categories, categoriesOrder]);

    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }

        let data = reorder(
          categoriesList,
          result.source.index,
          result.destination.index,
        );

        let sortData = [];
        data.map((item) => sortData.push(item.id));

        console.log('sortData --->>>', sortData);
        console.log('data --->>>', data);

        setCategoriesList(data);
        setSubmitData(sortData);

        // console.log('data', data);
    }

    const getCategories = (categoriesOrder) => {
        let list = [];
        let listCategories = {};

        let itemKeys = Object.keys(categories);
        let itemValues = Object.values(categories);

        for (let index in categoriesOrder) {
            if (itemKeys.includes(categoriesOrder[index])) {
                const itemIndex = itemKeys.findIndex(t => t === categoriesOrder[index]);
                const item = itemValues[itemIndex];

                let name = {};

                for (let key in languages) {
                    console.log('key', languages[key]);
                    if (languages[key].key == language) {
                        name[language] = item.name[language];
                    } 
                }

                list.push({
                    name,
                    id: categoriesOrder[index],
                    spots: item.spots
                });

                listCategories[categoriesOrder[index]] = {
                    name,
                    id: categoriesOrder[index],
                    spots: item.spots
                }
            }
        }
     
        props.updateCategories(listCategories);
    }

    const clearStateByProducts = () => {
        setIsSubmit(false);
        setSubmitData(null);
        // setCategoriesOrder(null);
        // setCategoriesList(null);
    };

    const sendToUpdateFirebase = (data) => {
        const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
        const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));

        console.log('keyCity ', keyCity , 'keyBrand', keyBrand)

        props.firebase.bd.ref(`${isDebug ? "debug" : ""}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`).set(data)
            .then(res => {
                console.log('updated categs')
                clearStateByProducts();
                updateCategoriesOrder(data);
                getCategories(data);
                getCategoriesData()
            })
            .catch(error => {
                console.error(error);
            });
    }


    const handleSubmit = (data) => {
        console.log('submit data', data);

        if (!data) {
            clearStateByProducts();
        } else {
            sendToUpdateFirebase(data);
        }
    }

    // console.log('state -->>>', state);

    return (
        <SortCategories 
            openModal={openModal}
            categoriesList={categoriesList}
            handleClose={handleClose}
            handleClickSubmit={handleClickSubmit}
            onDragEnd={onDragEnd}
            setOpenModal={setOpenModal}
        />
    )
}

export default connect(null, { updateCategories, updateCategoriesOrder, updateCategories })(withRouter(withFirebase(SortModeProducts)));