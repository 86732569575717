import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../../firebase';
import { useSelector, useDispatch } from 'react-redux';
import { editCategory, updateProductsDispatch, updateCategoriesOrder } from '../../../../reducers/shops';

import EditCategoryForm from './form';
import useStyles from './styles';

let Categories = (props) => {
    const categoryId = props.match.params.id;
    const classes = useStyles();
    const dispatch = useDispatch();
    const defaultLanguage = useSelector(state => state.shops.defaultLanguage);
    // const category = useSelector(state => state.shops.categories[categoryId]);
    const categories = useSelector(state => state.shops.categories);
    const cities = useSelector(state => state.shops.shops);
    const products = useSelector(state => state.shops.products);
    const language = useSelector(state => state.shops.defaultLanguage);
    const languages = useSelector(state => state.shops.languages);

    const [isSelectOpen, setIsSelectOpen] = useState(null);
    const [preloder, setPreloader] = useState(false);
    const [boxState, setBoxState] = useState(null);
    const [sortLanguages, setSortLanguages] = useState(null);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);
    const isCheckBoxList = boxState && Object.keys(boxState).length === selectedTerminals.length;

    const brand = JSON.parse(localStorage.getItem("currentBrand"));
    const isDebug = JSON.parse(localStorage.getItem("isDebug"))
    const category = categories.find(item => item.categoryId === categoryId)

    const changeSelectClose = () =>  setIsSelectOpen(false);
    const changeSelectOpen = () => setIsSelectOpen(true);

    useEffect(() => {
        // if (!languages) getLanguages();

        if (languages && languages.length > 0) {
            sortedLanguages();
        }
    }, [languages]);

    const sortedLanguages = () => {
        languages.sort((a, b) => {
            if (a.key !== defaultLanguage) {
                return 1;
            } else {
                return -1;
            }
        });

        setSortLanguages(languages);
    }

    const handleChange = (event) => {
        const data = [...selectedTerminals];
        const selectedTerminal = data.filter(t => t.visible === "1").length;
        const index = data.findIndex(t => t.id === event.target.name);

        if (selectedTerminal === 1 && data[index].visible === "1") {
            console.log('--- not change ---');
        } else {
            data[index] = { ...data[index], visible: data[index].visible === "1" ? "0" : "1" };
        }


        setSelectedTerminals(data)
    };

    const getDefaultCities = () => {
        let terminals = [];
        let address = [];
        let defaultSelectedCitiesArr = [];

        cities.map((city) => {
            address.push(city.address);
            for (let key in city.deliveryTerminals) {
                const terminal = city.deliveryTerminals[key];

                terminals.push({ ...terminal, cityId: city.spot_id });
            }
        });

        category && category.spots.map(spot => {
            const terminalId = spot.spot_id;
            for (let key in terminals) {
                const terminal = terminals[key];

                if (Number(terminalId) === Number(terminal.id) && spot.visible === "1") {
                    const { address } = cities.find(city => city.spot_id === terminal.cityId);
                   if (!defaultSelectedCitiesArr.includes(address)) {
                        defaultSelectedCitiesArr.push(address);
                   } 
                }
            }
        });

        return defaultSelectedCitiesArr;
    }

    const getTerminals = () => {
        console.log('--- getTerminals ---');

        const deliveryTerminals = brand.deliveryTerminals

        const terminals = deliveryTerminals.map(deliveryTerminal => {
            const spot = category?.spots.find(item => item.spotId === deliveryTerminal.id);
            return {
                ...deliveryTerminal,
                visible: spot?.visible
            };
        });

        return terminals;
    }

    const changeCity  = (data) => {
        let terminals = [];

        data.map(address => {
            let { spot_id, deliveryTerminals } = cities.find(city => city.address === address);

            deliveryTerminals.map((elem, index) => { 
                deliveryTerminals[index] = { ...elem, cityId: spot_id, visible: "1" };

                selectedTerminals.map(terminal => {
                    if (elem.id === terminal.id) {
                        let isVisible = false;

                        for (let key in terminal) {
                            if (key == 'visible') {
                                isVisible = true;
                            }
                        }

                        deliveryTerminals[index] = { ...terminal, cityId: spot_id, visible: terminal.visible };
                    }
                })
            });

            terminals.push(...deliveryTerminals);
        });

        setSelectedTerminals(terminals);
        setSelectedCities(data);
    }

    useEffect(() => {
        const terminals = getTerminals();
        if (selectedCities.length > 0 && selectedTerminals.length === 0 && category && category.spots) {
            const terminals = getTerminals();
            setSelectedTerminals(terminals);
        }
        
        if (terminals.length > 0 && selectedTerminals.length === 0) setSelectedTerminals(terminals);
    }, [selectedCities, category]);

    const createNewCategory = (data) => {
        let name = {};

        
        // for (let index in sortLanguages) {
        //     const { key } = sortLanguages[index];

        //     if (data[key]) {
        //         name[key] = data[key];
        //     }

        // }
        for (let key in data) {
            name[key] = data[key];
        }

        const spots = selectedTerminals.map(selectTerminal => 
            ({
                spotId: selectTerminal.id,
                visible: selectTerminal.visible,
            })
        )


        return {
            spots,
            name,
            categoryId: category.categoryId
        };
    }

    const updateProducts = (categoryId) => {
        const result = { ...products };

        for (let key in products) {
            let product = products[key];
            let newProduct = { ...product };
            let spots = [];

            if (categoryId === product.menuCategoryId) {
                for (let c in category.spots) {
                    if (category.spots && product.spots && category.spots.length !== product.spots.length) {
                        // console.log('-- 1 --', product);
                        let productSpots = product.spots.filter(item => !!item);
                        const categorySpot = category.spots[c]; 
                        const productSpot = productSpots[c]; 

                        // console.log('productSpot', productSpot);
                        
                        if (productSpot) {
                            spots.push({ 
                                ...productSpot, 
                                visible: productSpot.price === "0" && productSpot.visible === "0" || productSpot.price !== "0" && productSpot.visible === "0" ? "0" : categorySpot.visible
                            });
                        } else {
                            spots.push({ price: "0", spot_id: categorySpot.spot_id, visible: "0" });
                        }
                    }

                    if (!product || !product.spots || category.spots.length === product.spots.length) {
                        // console.log('-- 2 --', product);
                        let productSpots = product.spots.filter(item => !!item);

                        for (let p in productSpots) {
                            const categorySpot = category.spots[c]; 
                            const productSpot = productSpots[p];

                            if (categorySpot.spot_id === productSpot.spot_id) {
                                // console.log('categoryVisible, productVisible', categorySpot.visible, productSpot.visible);
                                const visible = productSpot.visible === "1" && productSpot.price > 0 ? "1" : "0";

                                // const visible = productSpot.price === "0" && productSpot.visible === "0" || productSpot.price !== "0" && productSpot.visible === "0" ? "0" : categorySpot.visible;
                                // const visible = productSpot.price === "0" && productSpot.visible === "0" ? "0" : productSpot.price > "0" && productSpot.visible === "1" ? "1" : categorySpot.visible;
                                spots.push({
                                     ...categorySpot, 
                                     price: productSpot.price, 
                                     visible
                                });
                            }
                        }
                    }
                }
                newProduct.spots = spots;
                result[key] = newProduct;
                // console.log('spots', spots);
            }
        }

        return result;
    }

    const onSubmit = (data) => {
        console.log('--- Submit data ---', data);
        const category = createNewCategory(data);
           console.log('updated category ***', category)
        setPreloader(true);

        props.firebase.bd.ref(`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/categories`)
        .once('value') 
        .then(snapshot => {
            const categories = snapshot.val() || []; 

            const updatedCategories = categories.map(item => item.categoryId === category.categoryId ? {...category} : {...item}) 
            props.firebase.bd.ref(`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/categories`)
                .set(updatedCategories)
                .then(() => {
                    editCategory(dispatch, category);
                    setPreloader(false);
                    props.history.push(`/page/menu/products/${brand.id}`);
                })
                .catch(error => {
                    console.error('Error updating category:', error);
                });
        })
        .catch(error => {
            console.error('Error retrieving categories:', error);
            setPreloader(false);
        });
        
    }

    return(
        <div className={classes.root}>
            <div className={classes.modal}>
                <EditCategoryForm
                    load={preloder} 
                    language={language}
                    languages={sortLanguages ? sortLanguages : []}
                    category={category}
                    cities={cities}
                    isCheckBoxList={isCheckBoxList}
                    selectedTerminals={selectedTerminals}
                    boxState={boxState}
                    onSubmit={onSubmit}
                    handleChange={handleChange}
                    changeSelectClose={changeSelectClose}
                    changeSelectOpen={changeSelectOpen}
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
                    changeCity={changeCity}
                />
            </div>
        </div>
    )
}

export default withFirebase(withRouter(Categories));